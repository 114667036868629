.ce_header {
    position: relative;
    z-index: 1;
}

.ce__header__mainheadline {
    hyphens: auto;
    h1 {
        margin-bottom: 2rem;
        margin-top: 1.5vh;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1;

        @media print, screen and (min-width: 40em) {
            font-size: 3.5rem;
        }
    }

    h2 {
        margin-top: 0;
        margin-bottom: 2vh;
        font-size: 1.75rem;
        line-height: 1.2;
        font-weight: 700;
        @media print, screen and (min-width: 40em) {
            font-size: 3rem;
        }
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
        @media print, screen and (min-width: 40em) {
            font-size: 1.75rem;
        }
    }
}

.ce__header__subheadline {
    p {
        margin: 0;
        line-height: 1.4;
    }
}

.ce__header_link {
    margin: 0 0 2rem 0;
}
