$galleryImagePadding: 1rem;
$galleryImageInTextPadding: .5rem;

.center-top,
.left-top,
.right-top {
    margin-bottom: $galleryImagePadding;
}

.center-bottom,
.left-bottom,
.right-bottom {
    margin-top: $galleryImagePadding;
}

.center-top,
.center-bottom {
    .row {
        justify-content: center;
    }
}

.right-top,
.right-bottom {
    .row {
        justify-content: right;
    }
}

.left-top,
.left-bottom {
    .row {
        justify-content: left;
    }
}

.intext-left {
    float: left;
    display: inline;
    margin-right: $galleryImageInTextPadding;
    margin-left: -8rem;
}

.intext-right {
    float: right;
    display: inline;
    margin-left: $galleryImageInTextPadding;
    margin-right: -8rem;
}

.intext-left,
.intext-right {

    @media screen and (max-width: 77em) {
        margin-left: 0;
    }

    @media screen and (max-width: 40em) {
        float: none;
        margin-left: 0;

        .item {
            width: 100%!important;
            height: auto!important;
            margin-top: $galleryImagePadding;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.image-behind {
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(60%);
        z-index: 0;
    }
}

.row {
    padding-bottom: $galleryImageInTextPadding;

    &:last-child {
        padding-bottom: 0;
    }
}

.item {
    padding-right: .5rem;

    &:last-of-type {
        padding-right: 0;
    }
}