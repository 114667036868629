.bodytextWithShowMore {

  transition: max-height 0.5s ease-in-out;

  > :first-child {
    margin-top: 0;
  }

  ul + ul {
    margin-top: -1rem;
  }
}

.showMore {
  position: relative;
  margin-top: 1rem;
  display: block;
  z-index: 10;
  width: 100%;
}