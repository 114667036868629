.editIcon {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  *[class*="ce--layout-bg-highlight-color"] &,
  *[class*="ce--layout-bg-color-dark"] & {
    /*! purgecss ignore current */
    color: #df1051;
  }
}
