.galleryImages {

  //max-height: 500px;

  .lazy-load-image-loaded {
    width: 100%!important;
  }

  img {
    //height: 65vh;
    //object-fit: cover;
    //@media print, screen and (min-width: 64em) {
    //  height: 100%;
    //}
    display: block;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.imageDescription {
  text-align: center;
  margin-top: 1rem;
  display: block;
  font-style: italic;
}

.swiperSlide {
  //max-height: 500px;
}