.bodytext {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.4;
    z-index: 1;
    word-break: break-word;
    overflow-x: auto;

    @media print, screen and (min-width: 40em) {
        overflow-x: visible;
    }
    
    table {
        width: 100% !important;
        margin: 1rem 0 0;
        @media print, screen and (min-width: 40em) {
            width: 100% !important;
        }   
    }
    > ol {
        margin-bottom: 0;
    }
    > ul {
        padding-left: 1.2rem;
        margin-bottom: 0;
    }
    p, li {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.bodytextImageBehind {
    color: #fff;
    padding: 1rem 2rem;
}

